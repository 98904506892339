const theme = {
	colors: {
		text: `white`,
		textLow: `#999`,
		link: `#58a6ff`,
		border: `#68699B`,
		background: `#211E55`,
		bg1: `#211E55`,
		bg2: `rgb(16,14,35)`,
		bga: `rgba(0,0,0,.3)`,
		accent: `#3B469B`,
		second: `#FFD780`,
	},
	fonts: {
		genshin: `"Genshin", sans-serif`,
		body: `sans-serif`,
		en: `微软雅黑,Microsoft YaHei,Neuropolitical,sans-serif`,
		ja: `游ゴシック体,Yu Gothic,YuGothic,ヒラギノ角ゴシック Pro,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,Osaka,ＭＳ Ｐゴシック,MS PGothic,sans-serif`,
	},
	styles: {
		root: {
			color: `text`,
			backgroundColor: `bg2`,
			margin: 0,
			padding: 0,
			boxSizing: `border-box`,
			fontFamily: `body`,
			fontSize: [2, 2, 3],
			lineHeight: 1.45,
			textRendering: `optimizeLegibility`,
			WebkitFontSmoothing: `antialiased`,
			MozOsxFontSmoothing: `grayscale`,
			"&[lang=ja]": { fontFamily: `ja`, },
			"&[lang=en]": { fontFamily: `en`, },
		},
		h1: {
			fontSize: [2, 2, 3],
			textAlign: `center`,
			fontWeight: `normal`,
			mt: 2,
		},
		h2: {
			fontSize: [4, 4, 5],
			mt: [`48px`,`48px`,5],
			mb: [3,3,`24px`],
		},
		h3: {
			fontSize: [3, 3, 4],
			my: 3,
		},
		p: {
			mt: 0,
			mb: 2,
		},
		a: {
			color: `link`,
			textDecoration: `none`,
		},
		ul: {
			p: 0,
			m: `0 0 8px 1.45rem`,
			listStylePosition: `outside`,
			listStyleImage: `none`,
		},
		img: {
			maxWidth: `100%`,
		},
		canvas: {
			maxWidth: `100%`,
		},
		label: {
			cursor: `pointer`,
		},
		hr: {
			color: `border`,
			mx: [4, 4, `48px`],
		},
	},
	layout: {
		container: {
			fontFamily: `inherit`,
			px: 3,
			maxWidth: `1024px`,
		},
	},
	forms: {
		input: {
			px: 3,
			py: 1,
			background: `transparent`,
			borderColor: `border`,
			"&[type='color']": {
				cursor: `pointer`,
				px: 1,
				py: 0,
			},
			":focus": {
				outlineOffset: `-1px`,
			},
			":-webkit-autofill": {
				textFillColor: `white`,
			},
		},
		textarea: {
			px: 3,
			py: 1,
			borderColor: `border`,
			":focus": {
				outlineOffset: `-1px`,
			},
			":-webkit-autofill": {
				textFillColor: `white`,
			},
		},
		select: {
			cursor: `pointer`,
			px: 3,
			py: 1,
			background: `transparent`,
			borderColor: `border`,
			"> option": {
				background: `bg2`,
			},
			":focus": {
				outlineOffset: `-1px`,
			},
			":-webkit-autofill": {
				textFillColor: `white`,
			},
		},
		checkbox: {
			height: [`21px`,`21px`,`24px`,],
			fill: `text`,
			mr: 1,
		},
		slider: {
			my: `auto`,
		},
	},
	buttons: {
		primary: {
			color: `text`,
			background: `bga`,
			border: `solid 1px`,
			borderColor: `border`,
			py: 1,
			cursor: `pointer`,
		},
	},
}

export default theme
