exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atk-crit-js": () => import("./../../../src/pages/atk-crit.js" /* webpackChunkName: "component---src-pages-atk-crit-js" */),
  "component---src-pages-avg-dmg-jsx": () => import("./../../../src/pages/avg-dmg.jsx" /* webpackChunkName: "component---src-pages-avg-dmg-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-player-card-fukafukafuka-29-jsx": () => import("./../../../src/pages/player-card-fukafukafuka29.jsx" /* webpackChunkName: "component---src-pages-player-card-fukafukafuka-29-jsx" */),
  "component---src-pages-player-card-jsx": () => import("./../../../src/pages/player-card.jsx" /* webpackChunkName: "component---src-pages-player-card-jsx" */),
  "component---src-pages-player-card-templates-jsx": () => import("./../../../src/pages/player-card-templates.jsx" /* webpackChunkName: "component---src-pages-player-card-templates-jsx" */),
  "component---src-pages-random-team-generator-tsx": () => import("./../../../src/pages/random-team-generator.tsx" /* webpackChunkName: "component---src-pages-random-team-generator-tsx" */),
  "component---src-pages-showcase-analytics-jsx": () => import("./../../../src/pages/showcase-analytics.jsx" /* webpackChunkName: "component---src-pages-showcase-analytics-jsx" */),
  "component---src-pages-showcase-jsx": () => import("./../../../src/pages/showcase.jsx" /* webpackChunkName: "component---src-pages-showcase-jsx" */),
  "component---src-pages-talent-materials-jsx": () => import("./../../../src/pages/talent-materials.jsx" /* webpackChunkName: "component---src-pages-talent-materials-jsx" */),
  "component---src-pages-team-builder-jsx": () => import("./../../../src/pages/team-builder.jsx" /* webpackChunkName: "component---src-pages-team-builder-jsx" */),
  "component---src-pages-wish-banner-jsx": () => import("./../../../src/pages/wish-banner.jsx" /* webpackChunkName: "component---src-pages-wish-banner-jsx" */)
}

