module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","ja"],"defaultLanguage":"en","siteUrl":"https://genshin.luis.fun","pages":[{"matchPath":"/404","languages":["en"]},{"matchPath":"/404.html","languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Paimon Lab - Genshin Tools","short_name":"Paimon Lab","lang":"en","start_url":"/","background_color":"#211E55","theme_color":"#211E55","display":"standalone","icons":[{"src":"/android-chrome-144x144.png","sizes":"144x144"},{"src":"/android-chrome-512x512.png","sizes":"512x512"},{"src":"/maskable_icon_x128.png","sizes":"128x128","purpose":"any maskable"},{"src":"/maskable_icon_x192.png","sizes":"192x192","purpose":"any maskable"}],"icon_options":{"type":"image/png"},"localize":[{"lang":"ja","start_url":"/ja/","name":"Paimon Lab - 原神ツール","short_name":"Paimon Lab"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"NetworkFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"}]}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
